import useSWR from "swr";
import Fetcher from "lib/fetcher";
import type { Notification } from "types/notification";

export default function useNotifications() {
  const fetcher = new Fetcher();

  const {
    data: notifications,
    mutate: mutateLocalNotifications,
    error,
  } = useSWR<Notification[]>("/api/notifications", fetcher.get);

  const markNotificationAsRead = (id: number) => fetcher.post(`/api/notifications/${id}/read`);
  const markAllNotificationsAsRead = () => fetcher.post("/api/notifications/read");

  const loading = !notifications && !error;

  return {
    loading,
    notifications,
    // mutateNotifications,
    mutateLocalNotifications,
    markNotificationAsRead,
    markAllNotificationsAsRead,
    error,
  };
}
