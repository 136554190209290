type Props = {
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  lineHeight?: "1" | "sm" | "base" | "lg";
  hideMargin?: boolean;
  className?: string;
  children: React.ReactNode;
};

const Heading = ({
  headingLevel = "h1",
  lineHeight = "base",
  hideMargin = false,
  className = "",
  children,
}: Props) => {
  const HeadingTag = headingLevel;
  return (
    <HeadingTag
      className={`font-monospace lh-${lineHeight} ${hideMargin ? "m-0" : ""} ${className}`}
    >
      {children}
    </HeadingTag>
  );
};

export default Heading;
