type Props = {
  hideMargin?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const Paragraph = ({ hideMargin = false, className = "", children }: Props) => {
  return <p className={`${hideMargin ? "mb-0" : ""} ${className}`}>{children}</p>;
};

export default Paragraph;
