import type { AppProps } from "next/app";
import { useEffect } from "react";
import { config as fortawesomeConfig } from "@fortawesome/fontawesome-svg-core";

import Layout from "@/layout";

fortawesomeConfig.autoAddCss = false;

import "styles/globals.scss";
import "@fortawesome/fontawesome-svg-core/styles.css";

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
}
