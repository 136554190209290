import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheck } from "@fortawesome/free-solid-svg-icons";

import Button from "components/buttons/button";
import Paragraph from "components/typography/paragraph";

import styles from "./notification.module.scss";
import { DateTime } from "luxon";

export type NotificationType = {
  id: number;
  text: string;
  date: string;
  is_unread: boolean;
  onClick?: (notificationId: number) => void;
};

const Notification = ({ id, text, date, is_unread, onClick }: NotificationType) => {
  const handleMarkNotificationsAsRead = () => {
    if (is_unread) onClick?.(id);
  };

  return (
    <div className={`p-2 position-relative rounded ${styles.notification}`}>
      <div className="d-flex flex-row">
        <Paragraph hideMargin className={styles.notificationTitle}>
          {text}
        </Paragraph>
      </div>
      <Paragraph hideMargin className={`fs-7 ${styles.notificationDate}`}>
        {DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)}
      </Paragraph>
      {is_unread ? (
        <div className="d-flex flex-row align-items-center mt-1">
          <div>
            <span className="badge text-bg-success flex-shrink-0">
              <FontAwesomeIcon className="me-1" icon={faCircle} size="xs" />
              New
            </span>
          </div>
          <Button
            color="light"
            size="sm"
            className="text-primary flex-shrink-0 ms-auto"
            onClick={handleMarkNotificationsAsRead}
          >
            <FontAwesomeIcon icon={faCheck} /> Mark as read
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Notification;
