import axios from "axios";
import config from "config";

export default class Fetcher {
  handleError = (error: any) => {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  };

  async get(url: string, opts?: { arg?: any }) {
    return axios
      .get(url, { params: { ...opts?.arg } })
      .then((res) => res.data)
      .catch((error) => this.handleError(error));
  }

  async post(url: string, opts?: { arg?: any }) {
    return axios
      .post(url, { ...opts?.arg })
      .then((res) => res.data)
      .catch((error) => this.handleError(error));
  }

  async put(url: string, { arg }: { arg: any }) {
    return axios
      .put(url, { ...arg })
      .then((res) => res.data)
      .catch((error) => this.handleError(error));
  }

  async delete(url: string) {
    return axios
      .delete(url)
      .then((res) => res.data)
      .catch((error) => this.handleError(error));
  }

  async postFormData(url: string, formData: FormData) {
    if (config.nodeEnv === "production") {
      return axios
        .post(url, formData)
        .then((res) => res.data)
        .catch((error) => this.handleError(error));
    } else {
      return axios
        .post(url, formData, { auth: { username: "dev", password: "d3vd3v" } })
        .then((res) => res.data)
        .catch((error) => this.handleError(error));
    }
  }
}
