import { useEffect } from "react";
import Router from "next/router";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import Fetcher from "lib/fetcher";
import type { User } from "types/user";

export default function useUser({ redirectTo = "", redirectIfFound = false } = {}) {
  const fetcher = new Fetcher();

  const { data: user, mutate: mutateLocalUser, error } = useSWR<User>("/api/user", fetcher.get);
  const { trigger: mutateUser } = useSWRMutation<User>("/api/user", fetcher.put);

  useEffect(() => {
    if (!redirectTo || !user) return;

    if (
      (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
      (redirectIfFound && user?.isLoggedIn)
    ) {
      Router.push(redirectTo);
    }
  }, [user, redirectIfFound, redirectTo]);

  return {
    user,
    mutateUser,
    mutateLocalUser,
    error,
  };
}
