import { forwardRef, ForwardRefRenderFunction } from "react";
import { type ElementColorWithLink, type ElementSize } from "types/element";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ElementColorWithLink;
  size?: ElementSize;
  children: React.ReactNode;
  overrideStyles?: string;
  className?: string;
  onClick?: () => void;
  showSpinner?: boolean;
}
export type Ref = HTMLButtonElement;

const Button: ForwardRefRenderFunction<Ref, Props> = (
  {
    color = "primary",
    size = "base",
    children,
    overrideStyles,
    className,
    onClick,
    showSpinner = false,
    ...props
  },
  ref
) => {
  let defaultStyles = `btn-${color} btn-${size}`;
  const classNames = clsx("btn", className, overrideStyles ? overrideStyles : defaultStyles);

  return (
    <button ref={ref} className={classNames} onClick={onClick} {...props}>
      {children} {showSpinner && <FontAwesomeIcon icon={faSpinner} spin size="lg" />}
    </button>
  );
};

const ButtonWithRef = forwardRef(Button);

export default ButtonWithRef;
