import Image from "next/image";
import Link from "next/link";
import config from "config";

import logo from "public/images/header/logo.png";
import Notifications from "components/notifications";
import useUser from "hooks/use-user";

const NavBar = () => {
  const { user } = useUser();

  return (
    <nav className="navbar navbar-light navbar-expand-md shadow" style={{ minHeight: 81 }}>
      <div className="px-sm-6 container mx-auto px-4">
        <div className="d-flex flex-fill justify-content-center justify-content-md-between">
          <Link
            className="navbar-brand flex-shrink-1"
            href={config.homepageUrl}
            aria-label="Dynamo Cover"
          >
            <Image
              priority
              className="img-fluid w-auto"
              src={logo}
              height="69"
              alt="Dynamo Cover"
            />
          </Link>
        </div>
        <div className="navbar-collapse flex-row-reverse">
          {user?.isLoggedIn && (
            <div className="d-flex justify-content-center">
              <Notifications />
              <Link className="btn btn-danger" href="/api/auth/logout">
                Logout
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
