import toNumber from "lodash.tonumber";

const nodeEnv = process.env.NODE_ENV || "development";
const basicAuthCredentials = process.env.BASIC_AUTH_CREDENTIALS || undefined;
const webappUrl = process.env.NEXT_PUBLIC_WEBAPP_URL || "http://localhost:3000";
const systemUserId = toNumber(process.env.SYSTEM_USER_ID) || 12345;
const homepageUrl = process.env.NEXT_PUBLIC_HOMEPAGE_URL || "https://dynamocover.com";

const insureEngineConfig: {
  url: string;
  token: string;
} = {
  url: process.env.NEXT_PUBLIC_INSURE_ENGINE_URL || "https://products.dynamocover.com",
  token: process.env.INSURE_ENGINE_TOKEN as string,
};

const ironSessionConfig: {
  password: string;
  ttl: unknown;
} = {
  password: process.env.IRON_SESSION_PASSWORD as string,
  ttl: process.env.IRON_SESSION_TTL || ((60 * 60 * 2) as number),
};

const stripeConfig = {
  pubKey: process.env.STRIPE_PUB_KEY,
  secretKey: process.env.STRIPE_SECRET_KEY,
  endpointSecret: process.env.STRIPE_ENDPOINT_SECRET,
  customerPortalReturnUrl: `${webappUrl}/account`,
  mtaAmount: 750,
  mtaAmountFloat: 7.5,
};

const companyName = process.env.NEXT_PUBLIC_COMPANY_NAME || "Dynamo Cover";
const companyEmail = process.env.NEXT_PUBLIC_COMPANY_EMAIL || "admin@dynamocover.com";
const companyFromEmail = process.env.NEXT_PUBLIC_COMPANY_FROM_EMAIL || "no-reply@dynamocover.com";
const companyPhone = process.env.NEXT_PUBLIC_COMPANY_PHONE || "0330 22 32 897";

const nodemailerConfig = {
  defaultEmailOpts: {
    from: `Dynamo Cover <${companyFromEmail}>`,
    replyTo: `Support <${companyEmail}>`,
  },
  transporterOpts: {
    host: process.env.SMTP_HOST,
    port: process.env.SMTP_PORT,
    auth: {
      user: process.env.SMTP_USER,
      pass: process.env.SMTP_PASSWORD,
    },
  },
};

const magicLinkExpiryTime = 15 * 60; // 15 minutes in seconds

const accessTokenExpiryTime = 120 * 60 * 1000; // 2 hours
const accessTokenExpiryBuffer = 5 * 60 * 1000; // 5 minute "buffer"
const sessionRefreshInterval = 5 * 60 * 1000;

const knexOpts = {
  client: "mysql",
  connection: {
    host: process.env.MYSQL_HOST || "localhost",
    port: process.env.MYSQL_PORT || 3306,
    user: process.env.MYSQL_USER || "dynamodev",
    password: process.env.MYSQL_PASSWORD || "password",
    database: process.env.MYSQL_DATABASE || "dynamodev",
  },
};

const config = {
  webappUrl,
  insureEngineConfig,
  systemUserId,
  homepageUrl,
  ironSessionConfig,
  nodeEnv,
  companyName,
  companyEmail,
  companyPhone,
  basicAuthCredentials,
  accessTokenExpiryTime,
  accessTokenExpiryBuffer,
  sessionRefreshInterval,
  knexOpts,
  stripeConfig,
  nodemailerConfig,
  magicLinkExpiryTime,
};

export default config;
