import { useState, useEffect } from "react";
import {
  useFloating,
  offset,
  shift,
  size,
  autoUpdate,
  useDismiss,
  useClick,
  useInteractions,
  FloatingPortal,
  FloatingOverlay,
} from "@floating-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell as farBell } from "@fortawesome/free-regular-svg-icons";
import { faBell as fasBell, faCheckDouble } from "@fortawesome/free-solid-svg-icons";

import Button from "components/buttons/button";
import Heading from "components/typography/heading";
import useNotifications from "hooks/use-notifications";
import { Notification as NotificationType } from "types/notification";
import Notification from "./notification";

const Notifications = () => {
  const [open, setOpen] = useState(false);
  const [hasUnreads, setHasUnreads] = useState(false);
  const {
    notifications,
    mutateLocalNotifications,
    markNotificationAsRead,
    markAllNotificationsAsRead,
  } = useNotifications();

  useEffect(() => {
    setHasUnreads(userHasUnreadNotifications(notifications));
  }, [notifications]);

  const userHasUnreadNotifications = (notifications: NotificationType[] | undefined) => {
    return notifications ? notifications.some((notification) => notification.is_unread) : false;
  };

  const onClickNotification = async (notificationId: number) => {
    try {
      await mutateLocalNotifications(markNotificationAsRead(notificationId), {
        revalidate: false,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleMarkAllNotificationsAsRead = async () => {
    try {
      await mutateLocalNotifications(markAllNotificationsAsRead(), {
        revalidate: false,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const floatingOffset = 10;

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(floatingOffset),
      size({
        apply({ availableHeight, elements }) {
          availableHeight -= floatingOffset;

          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          });

          const containerDiv = elements.floating.firstChild as HTMLDivElement;
          Object.assign(containerDiv?.style, {
            maxHeight: `${availableHeight}px`,
          });

          const notificationHeader = elements.floating.querySelector(
            "#notificationsHeader"
          ) as HTMLDivElement;
          const paddingBuffer = 16;
          const notificationHeaderHeight =
            notificationHeader.getBoundingClientRect().height + paddingBuffer;

          const notificationsList = elements.floating.querySelector(
            "#notificationsList"
          ) as HTMLDivElement;
          Object.assign(notificationsList?.style, {
            maxHeight: `${availableHeight - notificationHeaderHeight}px`,
          });
        },
        padding: 5,
      }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <Button
        ref={reference}
        className={`me-2 ${open ? "active" : ""}`}
        color="light"
        {...getReferenceProps()}
      >
        {hasUnreads ? (
          <FontAwesomeIcon icon={fasBell} size="lg" shake={!open ? true : false} />
        ) : (
          <FontAwesomeIcon icon={farBell} size="lg" />
        )}
      </Button>
      <FloatingPortal id="notifications-panel">
        {open && (
          <FloatingOverlay lockScroll>
            <div
              {...getFloatingProps({
                className: "bg-light text-dark shadow border rounded",
                ref: floating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  width: 400,
                  maxWidth: "100%",
                },
              })}
            >
              <div className="p-2">
                <div id="notificationsHeader" className="d-flex flex-column">
                  <div className="p-2 d-flex flex-column justify-content-center flex-sm-row justify-content-sm-between align-items-sm-center">
                    <Heading
                      className="text-center text-sm-start text-dynamo"
                      headingLevel="h5"
                      hideMargin
                    >
                      Notifications
                    </Heading>
                    <Button
                      color="light"
                      size="sm"
                      className="text-primary flex-shrink-0"
                      onClick={handleMarkAllNotificationsAsRead}
                      disabled={!hasUnreads}
                    >
                      <FontAwesomeIcon icon={faCheckDouble} /> Mark all as read
                    </Button>
                  </div>
                  <hr className="my-2" />
                </div>
                <div id="notificationsList" className="overflow-auto">
                  {notifications &&
                    notifications.map((notification: NotificationType) => (
                      <Notification
                        key={`notification_${notification.id}`}
                        onClick={onClickNotification}
                        {...notification}
                      />
                    ))}
                  {!notifications ||
                    (notifications.length === 0 && (
                      <Heading className="text-center py-3" hideMargin headingLevel="h5">
                        You don’t have any notifications
                      </Heading>
                    ))}
                </div>
              </div>
            </div>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </>
  );
};

export default Notifications;
