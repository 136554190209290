import Link from "next/link";

import Paragraph from "components/typography/paragraph";
import config from "config";

type FooterLinkProps = {
  href: string;
  children: React.ReactNode;
};

const FooterLink = ({ href, children }: FooterLinkProps) => {
  return (
    <Link href={href} className="hover:underline">
      {children}
    </Link>
  );
};

type FooterProps = {};

const Footer = ({}: FooterProps) => {
  const now = new Date();

  return (
    <footer className="py-md-5 bg-light mt-5 py-4">
      <div className="px-sm-6 container mx-auto px-4 pt-8 pb-12">
        <Paragraph>
          &copy; {now.getFullYear()} Dynamo Cover | Registered in England &amp; Wales
        </Paragraph>
        <Paragraph>
          Dynamo Cover is a trading style of Dynamo Cover Limited which is authorised and regulated
          by the Financial Conduct Authority. Registration Number: 781314
        </Paragraph>
        <Paragraph hideMargin>Address: Cardiff House, Cardiff Road, Barry, CF63 2AW</Paragraph>
      </div>
    </footer>
  );
};

export default Footer;
