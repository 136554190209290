import NavBar from "./navbar";
import Footer from "./footer";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <div>
      <div>
        <NavBar />
        {children}
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
